import React from 'react'
import StarTutoringLayout from '../../components/pages/StarTutoring/StarTutoringLayout'

const StarTutoringDashboard = (props: any) => {
  return <StarTutoringLayout path={props.path}>
    <div>Work in progress</div>
  </StarTutoringLayout>
}

export default StarTutoringDashboard
